<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>

    <v-card class="mt-10" v-if="data_loaded">
      <v-card-title>
        <h4>طلبات التحويل</h4>
        <v-spacer></v-spacer>
        <v-text-field
          hide-details
          prepend-icon="search"
          v-model="search"
          label="بحث"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :mobile-breakpoint="0"
        :headers="requests_headers"
        :items="transfares"
        :search="search"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          USDT
          {{ Intl.NumberFormat("en-US").format(item.amount.toFixed(1)) }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span class="font-weight-bold primary--text" v-if="item.type == 0">
            <small> اضافة رصيد</small>
          </span>
          <span class="font-weight-bold success--text" v-else>
            <small> تحويل مبلغ</small>
          </span>
        </template>
        <template v-slot:[`item.method`]="{ item }">
          {{ item.method ? item.method : "__" }}
        </template>
        <template v-slot:[`item.account_number`]="{ item }">
          {{ item.account_number ? item.account_number : "__" }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-if="item.status == 0" color="warning" x-small>
            جاري العمل ...
          </v-chip>
          <v-chip v-else-if="item.status == 1" color="success" x-small>
            {{ item.type == 0 ? "تم الأضافة" : "تم التحويل" }}
          </v-chip>
          <v-chip v-else color="error lighten-3" x-small> تم الألغاء </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            @click="open_dialog(item, 1)"
            v-if="item.status == 0"
            class="me-1"
            fab
            elevation="1"
            color="success"
            x-small
          >
            <v-icon>check</v-icon>
          </v-btn>
          <v-btn
            @click="open_dialog(item, 2)"
            v-if="item.status == 0"
            fab
            elevation="1"
            color="error"
            x-small
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog persistent max-width="400" v-model="dialog">
      <v-card>
        <v-card-title class="d-block">
          <v-alert
            class="text-center white--text"
            dense
            :color="value == 1 ? 'success' : 'error'"
            >{{ dialog_text }}</v-alert
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="update_load"
            :loading="update_load"
            @click="update_req()"
            color="primary"
            >نعم</v-btn
          >
          <v-btn @click="close_dialog()" color="error" text>لا</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Transfares",
  data() {
    return {
      update_load: false,
      data_loaded: false,
      tab: 0,
      search: "",
      requests_headers: [
        {
          align: "center",
          text: "تاريخ الارسال",
          value: "created_at",
        },
        {
          align: "center",
          text: "المبلغ",
          value: "amount",
        },
        {
          align: "center",
          text: "صاحب الطلب",
          value: "name",
        },
        {
          align: "center",
          text: "نوع المعاملة",
          value: "type",
        },
        {
          align: "center",
          text: "طريقة الدفع",
          value: "method",
        },
        {
          align: "center",
          text: "رقم الحساب",
          value: "account_number",
        },
        {
          align: "center",
          text: "حالة الطلب",
          value: "status",
        },
        {
          align: "center",
          text: "اجراءت",
          value: "actions",
        },
      ],
      requred_id: null,
      value: null,
      amount: null,
      user_id: null,
      dialog_text: "",
      dialog: false,
      transfares: [],
    };
  },
  methods: {
    open_dialog(item, value) {
      this.dialog = true;
      this.requred_id = item.id;
      this.user_id = item.user_id;
      this.amount = item.amount;
      this.value = value;
      if (value == 1) {
        this.dialog_text = "تأكيد اتمام التحويل ؟ ";
      } else {
        this.dialog_text = "تأكيد الغاء التحويل ؟ ";
      }
    },
    get_transfers() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "transfers",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.transfares = res.data.data;
        });
    },
    update_req() {
      if (!this.update_load) {
        this.update_load = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `transfers/${this.requred_id}`,
              method: "put",
            },
            data: {
              value: this.value,
              user_id: this.user_id,
              amount: this.amount,
            },
          })
          .then((res) => {
            this.$store.state.transfers = this.$store.state.transfers - 1;
            this.get_transfers();
            this.close_dialog();
          }).catch(err=> {
            console.log(err.response);
          })
      }
    },
    close_dialog() {
      this.dialog = false;
      this.value = null;
      this.requred_id = null;
      this.user_id = null;
      this.amount = null;
      this.update_load = false;
    },
  },
  mounted() {
    this.get_transfers();
  },
};
</script>
